<template>
       <!-- Sidebar-->
       <div class="border-end bg-white d-flex flex-column justify-content-between" id="sidebar-wrapper">
            <div class="list-group list-group-flush">
              <div class="sidebar-heading border-bottom bg-light">
                  <span class="fw-bold" style="color: #65529d">Psico Prontuários</span>
              </div>

              <router-link
                  to="/pacientes"
                  class="list-group-item list-group-item-action p-3"
                  :class="{ 'active-link': $route.path === '/pacientes' }"
                  v-if="tipo_usuario != 'afiliado'"
              >
                <i class="fa-solid fa-users"></i>
                Pacientes
              </router-link>
              
              <router-link
                  to="/agendamentos"
                  class="list-group-item list-group-item-action p-3"
                  :class="{ 'active-link': $route.path === '/agendamentos' }"
                  v-if="tipo_usuario != 'afiliado'"
              >
                <i class="fa-solid fa-calendar"></i>
                Agendamentos
              </router-link>

              <router-link
                  to="/financeiro"
                  class="list-group-item list-group-item-action p-3"
                  :class="{ 'active-link': $route.path === '/financeiro' }"
                  v-if="tipo_usuario != 'afiliado'"
              >
                <i class="fa-solid fa-sack-dollar"></i>
                Financeiro
              </router-link>

              <router-link
                  to="/perfil"
                  class="list-group-item list-group-item-action p-3"
                  :class="{ 'active-link': $route.path === '/perfil' }"
              >
                <i class="fa-solid fa-gear"></i>
                Perfil
              </router-link>
              
              <!-- <div class="list-group-item list-group-item-action p-3 cursor-pointer d-flex justify-content-between align-items-center" @click="toggleCollapse('taxasEntregas')" data-bs-toggle="collapse" data-bs-target="#taxasEntregas" aria-expanded="false" aria-controls="taxasEntregas">
                  <div>
                      <svg width="20" height="20" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                        <path d="M21 21v-9.75"></path>
                        <path d="M3 11.25V21"></path>
                        <path d="M17.928 2.25H6.072c-1.022 0-1.944.563-2.34 1.428l-2.03 4.431c-.684 1.491.45 3.18 2.211 3.235h.094c1.472 0 2.665-1.18 2.665-2.449 0 1.266 1.193 2.449 2.665 2.449S12 10.248 12 8.895c0 1.266 1.193 2.449 2.665 2.449s2.665-1.096 2.665-2.449c0 1.353 1.193 2.449 2.665 2.449h.091c1.76-.055 2.896-1.744 2.212-3.235l-2.03-4.431c-.396-.865-1.319-1.428-2.34-1.428Z"></path>
                        <path d="M1.5 21.75h21"></path>
                        <path d="M6.375 13.5h3.75a1.125 1.125 0 0 1 1.125 1.125v4.125h-6v-4.125A1.125 1.125 0 0 1 6.375 13.5Z"></path>
                        <path d="M13.5 21.75v-7.125a1.125 1.125 0 0 1 1.125-1.125h3a1.125 1.125 0 0 1 1.125 1.125v7.125"></path>
                      </svg>
                      Restaurante
                  </div>
                  <i :class="{'fa-chevron-up': isCollapseOpen('taxasEntregas'), 'fa-chevron-down': !isCollapseOpen('taxasEntregas')}" class="fa-solid me-1"></i>
              </div>

              <div class="collapse" id="taxasEntregas">
                  <router-link :to="{name: 'taxasEntregas'}" class="list-group-item list-group-item-action p-3">
                    Taxas de entregas
                  </router-link>
                  <router-link :to="{name: 'cupons'}" class="list-group-item list-group-item-action p-3">
                    Cupons
                  </router-link>
              </div> -->
            </div>
          <div class="list-group list-group-flush">
              <router-link
                  to="/chamados"
                  class="list-group-item list-group-item-action p-3"
                  :class="{ 'active-link': $route.path === '/chamados' }"
              >
                <i class="fa-solid fa-handshake-angle"></i>
                Chamados e Ajuda
              </router-link>

              <li class="list-group-item list-group-item-action p-3 cursor-pointer" style="color: #65529d;" @click="logout">
                <i class="fa-solid fa-power-off"></i>
                Sair
              </li>
            </div>
      </div>
</template>

<script>
export default {
  props: ['userData'],
  data() {
    return {
      openCollapses: [],
      usuario: null,
      tipo_usuario: '',
    };
  },
  methods: {
    toggleCollapse(collapseId) {
      const index = this.openCollapses.indexOf(collapseId);
      if (index === -1) {
        this.openCollapses.push(collapseId);
      } else {
        this.openCollapses.splice(index, 1);
      }
    },
    isCollapseOpen(collapseId) {
      return this.openCollapses.includes(collapseId);
    },
    logout() {
      sessionStorage.removeItem('usuario');
      sessionStorage.removeItem('sessionTime');
      this.fezLogout = true;
      this.$router.push('/');
    } 
  },
  mounted() {
    this.usuario = null;

    if (this.usuario = JSON.parse(sessionStorage.getItem("usuario")) != null) {
      this.usuario = JSON.parse(sessionStorage.getItem("usuario")).usuario
      this.tipo_usuario = this.usuario.tipo_usuario
      return;
    }

    if (this.$props.userData) {
      this.usuario = this.$props.userData
      this.tipo_usuario = this.usuario.tipo_usuario
      return;
    }

  }
}
</script>

<style scoped>
.active-link {
  background-color: #D1C1FF;
  color: #65529d !important;
}

.active-link:hover {
  background-color: #D1C1FF;
  color: #65529d;
}

.list-group-item-action:hover, .list-group-item-action:focus {
  background-color: #D1C1FF;
  color: #65529d !important;
}

.list-group-item {
  color: #D1C1FF;
}
</style>